<template>
    <div>
        <b-row class="px-2">
            <b-col v-for="(assets, key) in assetsBy10" :key="key">
                <b-row>
                    <b-col sm="6" class="section-header border border-light px-1">Asset Type</b-col>
                    <b-col sm="3" class="section-header border border-light px-1">DISPATCH</b-col>
                    <b-col sm="3" class="section-header border border-light px-1">RECEIPT</b-col>
                </b-row>
                <b-row v-for="(item, key2) in assets" :key="key2">
                    <b-col sm="6" class="form-field form-field-string border border-light border-1">
                        {{ getAssetType(item.assetType) }}<span v-if="item.assetType.length === 0">&nbsp;</span>
                    </b-col>
                    <b-col sm="3" class="form-field form-field-number border border-light border-1 ">
                        {{ item.expectedQuantity }}
                    </b-col>
                    <b-col sm="3" class="form-field form-field-number border border-light border-1">
                        <span v-if="selDispatch.status === 'Received'"
                            :class="hasDiscrepancy(item) ? 'has-discrepancy' : ''">
                            {{ item.actualQuantity }}
                        </span>
                        <span v-else>
                            &nbsp;&nbsp;
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import _ from 'lodash';

export default {
    name: 'dispatch-summary-asset-types',
    props: {
        selDispatch: {
            type: Object,
            required: true,
        },
        assetsObj: {
            type: Object,
            required: true,
        }
    },
    computed: {
        assets() {
            let assets = this.selDispatch && this.selDispatch.assets ? this.selDispatch.assets : [];
            let actualAssets = this.selDispatch && this.selDispatch.actualAssets ? this.selDispatch.actualAssets : [];

            let assetsArr = [];

            let assetTypes = [];
            if (this.assetsObj && !_.isEmpty(this.assetsObj)) {
                assetTypes = _.uniq(_.map(this.assetsObj, 'assetType'));
            }

            if (assetTypes && assetTypes.length > 0) {
                let index = 0;
                _.forEach(assetTypes, assetType => {
                    assetsArr.push({
                        id: index,
                        assetType: assetType,
                        expectedQuantity: this.getExpectedCount(assets, assetType),
                        actualQuantity: this.getActualCount(actualAssets, assetType),
                    });
                    index++;
                });
            }

            let currCount = _.size(assetsArr);
            let maxCount = this.getMaxCount(currCount);

            for (let i = currCount; i < maxCount; i++) {
                assetsArr.push({
                    index: i,
                    assetType: '',
                    expectedQuantity: '',
                    actualQuantity: '',
                });
            }

            return assetsArr;
        },
        assetsBy10() {
            return _.chunk(this.assets, 10);
        }
    },
    methods: {
        getMaxCount(currCount) {
            let maxCount = currCount;
            if (currCount <= 10) {
                maxCount = 10;
            } else if (currCount <= 20) {
                maxCount = 20;
            } else if (currCount <= 30) {
                maxCount = 30;
            }
            return maxCount;
        },
        getExpectedCount(assets, assetType) {
            let assetsObj = {};

            _.forEach(assets, assetCode => {
                if (this.assetsObj[assetCode].assetType === assetType) {
                    assetsObj[assetCode] = this.assetsObj[assetCode];
                }
            })

            return _.size(assetsObj);
        },
        getActualCount(actualAssets, assetType) {
            let assetsObj = {};

            _.forEach(actualAssets, assetCode => {
                if (this.assetsObj[assetCode].assetType === assetType) {
                    assetsObj[assetCode] = this.assetsObj[assetCode];
                }
            })

            return _.size(assetsObj);
        },
        hasDiscrepancy(item) {
            return item.actualQuantity !== item.expectedQuantity;
        },
        getAssetType(assetTypeName) {
            const maxCount = _.size(this.assets);

            if (maxCount > 20 && assetTypeName.length > 23) {
                return assetTypeName.substring(0, 20) + '...';
            } else if (maxCount > 10 && maxCount <= 20 && assetTypeName.length > 40) {
                return assetTypeName.substring(0, 37) + '...';
            }
            return assetTypeName;
        }
    }
}
</script>

<style scoped>
.section-header {
    text-transform: uppercase;
    color: #122C91;
    font-size: small;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-align: center;
}

.form-field {
    font-size: small;
    text-transform: uppercase;
    color: #4A4A4A;
    font-weight: bold;
    line-height: normal;
}

.has-discrepancy {
    color: #f44335;
}

.form-field-string {
    text-align: left !important;
}

.form-field-number {
    text-align: center !important;
}
</style>