<template>
    <div>
        <b-row class="px-2">
            <b-col>
                <b-row>
                    <b-col sm="3" class="section-header border border-light px-1">Asset Code</b-col>
                    <b-col sm="4" class="section-header border border-light px-1">Asset Type</b-col>
                    <b-col sm="4" class="section-header border border-light px-1">Description</b-col>
                    <b-col sm="1" class="section-header border border-light px-1">Received?</b-col>
                </b-row>
                <b-row v-for="item in assets" :key="item.id">
                    <b-col sm="3" class="form-field form-field-string border border-light border-1 px-1">
                        {{ getAssetCodeDisplay(item.assetCode) }}<span v-if="item.assetCode.length === 0">&nbsp;</span>
                    </b-col>
                    <b-col sm="4" class="form-field form-field-string border border-light border-1 px-1">
                        {{ getAssetTypeDisplay(item.assetType) }}
                    </b-col>
                    <b-col sm="4" class="form-field-regular border border-light border-1">
                        <span class="description">{{ getDescriptionDisplay(item.description) }}</span>
                    </b-col>
                    <b-col sm="1" class="form-field border border-light border-1">
                        <span :class="item.received === 'NO' ? 'not-received' : ''">
                            {{ item.received }}
                        </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import _ from 'lodash';

export default {
    name: 'dispatch-summary-assets',
    props: {
        selDispatch: {
            type: Object,
            required: true,
        },
        assetsObj: {
            type: Object,
            required: true,
        }
    },
    computed: {
        assets() {
            let assetsArr = [];
            let index = 0;
            _.forEach(this.assetsObj, (asset) => {
                assetsArr.push({
                    id: index,
                    assetCode: asset.assetCode,
                    assetType: asset.assetType,
                    description: this.getDescription(asset),
                    received: this.isReceived(asset)
                });
                index++;
            });

            let currCount = _.size(assetsArr);
            let maxCount = this.getMaxCount(currCount);

            for (let i = currCount; i < maxCount; i++) {
                assetsArr.push({
                    id: i,
                    assetCode: '',
                    assetType: '',
                    description: '',
                    received: '',
                });
            }

            return assetsArr;
        },
    },
    methods: {
        getMaxCount(currCount) {
            let maxCount = currCount;
            if (currCount < 20) {
                maxCount = 20;
            }
            return maxCount;
        },
        getDescription(asset) {
            let details = asset.details ? asset.details : {};
            return details.description ? details.description : '-';
        },
        isReceived(asset) {
            let assetCode = asset.assetCode;

            if (this.selDispatch.status === 'Received') {
                if (this.selDispatch.actualAssets.includes(assetCode)) {
                    return 'YES';
                } else {
                    return 'NO';
                }
            } else {
                return '-';
            }
        },
        getAssetCodeDisplay(assetCode) {
            if (assetCode.length > 30) {
                return assetCode.substring(0, 30) + '...';
            }
            return assetCode;
        },
        getAssetTypeDisplay(assetType) {
            if (assetType.length > 45) {
                return assetType.substring(0, 45) + '...';
            }
            return assetType;
        },
        getDescriptionDisplay(description) {
            if (description.length > 58) {
                return description.substring(0, 58) + '...';
            }
            return description;
        }
    }
}
</script>

<style scoped>
.section-header {
    text-transform: uppercase;
    color: #122C91;
    font-size: small;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    padding-bottom: 1px;
}

.form-field {
    font-size: small;
    text-transform: uppercase;
    line-height: small;
    color: #4A4A4A;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-bottom: 1px;
}

.form-field-string {
    text-align: left !important;
}

.form-field-number {
    text-align: right !important;
}

.form-field-regular {
    font-size: small;
    text-align: left;
    line-height: small;
    color: #4A4A4A;
    line-height: normal;
    text-align: left;
    padding-bottom: 1px;
}

.not-received {
    color: #f44335;
}

.description {
    font-size: small;
}
</style>