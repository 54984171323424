// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import axios from 'axios';
import config from '@/config/env-constants';


function getProjectId() {
	if (config.currEnv === 'pjl') {
		return `ayun-${config.currEnv}`;
	} else {
		return `ares-${config.currEnv}-app`;
	}
}

export default {

	baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

	getDispatches(filterBy, view, currUserId) {
		const url = `${this.baseUrl}/getDispatches`;
		return axios.post(url, {
			view: view,
			currUserId: currUserId,
			filterBy: JSON.stringify(filterBy)
		});
	},

	saveDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/saveDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	deployDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/deployDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	cancelDispatch(dispatch, currUserId) {
		let url = `${this.baseUrl}/cancelDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			dispatch: JSON.stringify(dispatch)
		});
	},

	receiveDispatch(dispatch, currUserId, currTimeStamp) {
		let url = `${this.baseUrl}/receiveDispatch`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: currTimeStamp,
			dispatch: JSON.stringify(dispatch)
		});
	},

	validateReceive(dispatch, currUserId) {
		let url = `${this.baseUrl}/validateReceive`;
		return axios.post(url, {
			currUserId: currUserId,
			dispatch: JSON.stringify(dispatch)
		});
	},

	paginateAssetsForDispatch(page, limit, startAt, direction, filters, currUserId) {
        let url = `${this.baseUrl}/paginateAssetsForDispatch`;
        return axios.post(url, { ...filters, page, limit, startAt, direction, currUserId });
    },
}
